<template>
  <div class="CustomersTable">
    <h3 class="title  mb-3">
      {{ t($route.params.report_id) }}
      <button class="button is-pulled-right"
              :disabled="exporting"
              @click="exportReport">
        <span class="icon">
          <span :class="['fas', {
            'fa-file-excel': !exporting,
            'fa-spin fa-spinner': exporting,
          }]"></span>
        </span>
        <span>{{ t('Download Report') }}</span>
      </button>
    </h3>

    <Table
      :loading="loading"
      :rowClickHandler="rowClick"
      @newPage="getNewPage"
      :pageData="pageData"
      :headers="headers"
      :rows="rows"
      :breakingPoint="600"
    />
  </div>
</template>

<script>
import { useToast } from "vue-toastification";

import Table from "../../../components/Dynamic/Table.vue";

import {mapActions} from "vuex";

export default {
  name: "ViewReport",
  components: { Table },
  data() {
    return {
      loading: false,
      exporting: false,
      pageData: {
        currentPage: 1,
        lastPage: 1,
      },
      headers: this.$store.getters["TableHeaders/getHeaders"](
          this.$route.params.report_id.replace(/_report$/, '')
      ),
      rows: [],
      t: this.$store.getters["Translations/getTranslations"](
          this.$options.name
      ),
    };
  },
  watch: {
    "$store.state.me.activeSeller"() {
      this.getNewPage();
      this.rows = [];
    },
  },
  mounted() {
    this.getNewPage();
    this.$toast = useToast();
    console.log("headers", this.headers);
  },
  methods: {
    ...mapActions(["dataviewKit/getViewData", "exportKit/postExportViewDataset"]),
    getNewPage(page = 1) {
      this.loading = true;
      this["dataviewKit/getViewData"](
        {
          view: this.$route.params.report_id,
          page: page,
          parameters: {
            owner_identifier: (
                this.$store.state.me.activeSeller ??
                this.$store.state.me.user.companies[0] ?? { identifier: "" }
            ).identifier,
          },
        },
        { "content-type": "application/json" }
      )
        .then((data) => {
          this.pageData = {
            currentPage: data.current_page,
            lastPage: data.last_page,
          };
          this.rows = data.data;
          this.loading = false;
        })
        .catch((data) => {
          console.error(data);
          this.$toast.error(data, {});
          this.loading = false;
        });
    },
    rowClick(gRow = {}) {
      console.log("row click,", gRow);
      switch(this.$route.params.report_id) {
        case "cm_incaze_nl_all_disputes_report":
          this.$store.state.emitter.$emit('viewDispute', gRow.dispute)
        break
        case "cm_incaze_nl_all_payment_promises_report":
          this.$store.state.emitter.$emit('viewPaymentPromise', gRow.promise)
        break
        case "cm_incaze_nl_all_payment_plans_report":
          this.$store.state.emitter.$emit('viewPaymentPlan', gRow.plan)
        break
      }
    },
    exportReport() {
      this.exporting = true;
      this["exportKit/postExportViewDataset"](
        {
          view: this.$route.params.report_id,
          paginated: false,
          parameters: {
            owner_identifier: (
                this.$store.state.me.activeSeller ??
                this.$store.state.me.user.companies[0] ?? { identifier: "" }
            ).identifier,
          },
          the_headers: this.headers.map((item) => {
            return item.name
          }),
        },
        { "content-type": "application/json" }
      )
        .then((data) => {
          if(data.success) {
            var a = document.createElement('a');
            a.href = data.file.url;
            a.target = '_blank';
            a.download = `${this.$route.params.id}.csv`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          } else { this.$toast.error("Something went wrong", {}); }
          this.exporting = false;
        })
        .catch((data) => {
          console.error(data);
          this.$toast.error(data, {});

          this.exporting = false;
        });
    },
  },
};
</script>
