<template>
  <div id="Report">
    <Table />
    <router-view></router-view>
  </div>
</template>

<script>
import Table from "./Table.vue";

export default {
  components: { Table },
};
</script>
